import React, { useState, useEffect } from "react";
import { ActivityToken, ApiEndPoint } from "../GlobalData/GlobalData";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { ActivitiesListing } from "../../Redux/Actions/actions";
import { useDispatch } from "react-redux";
import Axios from "axios";
function ActivitySearch() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [selectedActivityDate, setSelectedActivityDate] = useState(null);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleActivityDateChange = (date) => {
    setSelectedActivityDate(date);
  };

  let debounceTimeout;

  const fetchOptionsFromAPI = async (inputValue) => {
    var endpoint = ApiEndPoint();
    var token = ActivityToken();
    var data = {
      token: token,
      location: inputValue,
    };
    try {
      const response = await Axios.post(
        endpoint + "/api/cites_suggestions", data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data.locations;
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const debouncedFetchOptions = (value) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(async () => {
      const result = await fetchOptionsFromAPI(value);
      const transformedLocations = result.map((item, index) => ({
        label: item.location,
        value: index, // You can use a unique identifier as the value, for example, item.id if available
      }));
      setOptions(transformedLocations);
    }, 300);
  };

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, []);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    debouncedFetchOptions(newValue);
  };

  const handleChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const show = async () => {
    var endpoint = ApiEndPoint();
    var token = ActivityToken();
    var data = {
      token: token,
      location: selectedCity?.label,
      start_dates: moment(selectedActivityDate).format("DD-MM-YYYY"),
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        endpoint + "/api/search_activities_react",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setIsLoading(false);
      dispatch(ActivitiesListing(response.data));
      navigation("/activities");
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {isLoading && <Loading />}
      <div
        class="tab-pane "
        id="hotels"
        role="tabpanel"
        aria-labelledby="hotels-tab"
      >
        <div class="row">
          <div class="col-lg-12">
            <div class="tour_search_form">
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div class="flight_Search_boxed">
                    <p>Where To</p>
                    <Select
                      options={options}
                      onInputChange={handleInputChange}
                      onChange={handleChange}
                      placeholder="Type to search..."
                      isSearchable
                      isClearable={true}
                      value={selectedCity}
                    />
                    {/* <span>Where are you going?</span> */}
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div class="flight_Search_boxed">
                    <p>Departure</p>
                    <DatePicker
                      selected={selectedActivityDate}
                      onChange={handleActivityDateChange}
                      minDate={new Date()}
                      placeholderText="Select Date"
                      dateFormat="dd/MM/yyyy" // Customize date format as needed
                      className="form-control w-100 "
                    />
                    {/* <span>Select Date</span> */}
                  </div>
                </div>

                <div class="top_form_search_button">
                  <button
                    class="btn btn_theme btn_md"
                    type="submit"
                    onClick={show}
                    value="Search"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivitySearch;
