import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HotelDetail from "../../Pages/Hotels/HotelDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faGlobe,
  faCheck,
  faArrowRight,
  faInfo,
  faInfoCircle,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import Axios from "axios";
import moment from "moment";
import iLoading from "../../Images/Loading/Loader.gif";
import Loading from "../Loading/Loader";
import { useDispatch } from "react-redux";
import {
  ApiEndPoint,
  Hotelapitoken,
  CurrencyConverter,
} from "../GlobalData/GlobalData";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { fetchHotelDetail, fetchHotels } from "../../Redux/Actions/actions";
import { toast, ToastContainer } from "react-toastify";


function HotelCard({ hotelid, hotelDataNew, hotelsSearchData }) {
  const dispatch = useDispatch();
  var endpoint = ApiEndPoint();
  var apitoken = Hotelapitoken();
  const [hotelsData, setHotelsData] = useState(hotelDataNew);
  const ReduxSearchData = hotelsSearchData;
  const [searchData, setSearchData] = useState({
    checkin: "",
    checkout: "",
    adult: 0,
    child: 0,
  });
  var filteredHotels = [];
  const navigate = useNavigate();
  const [hotels, setHotels] = useState(hotelsData);
  const [numberOfNights, setNumberOfNights] = useState("");
  const [Loadingpage, setLoadingpage] = useState(false);
  const [visibleHotels, setVisibleHotels] = useState(10);
  const [hotelDetails, setHotelDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [baseCName, setBaseCName] = useState("GBP");
  const [baseCurrency, setBaseCurrency] = useState([]);
  const [showHotels, setShowHotels] = useState([]);
  const [hotelsListing, setHotelsListing] = useState(
    hotelsData.sort((a, b) => Number(a.min_price) - Number(b.min_price))
  );
  const [showPrice, setShowPrice] = useState(false);
  const CurrencyRates = useSelector((state) => state.hotels.Currency);
  const GBPCurrencyRates = useSelector((state) => state.hotels.AllCurrency);
  var [currentPage, setCurrentPage] = useState(1);
  var TransferPerPage = 21; // Number of hotels to display per page
  var indexOfLastTransfer = currentPage * TransferPerPage;
  var indexOfFirstTransfer = indexOfLastTransfer - TransferPerPage;
  var currentHotels = hotelsListing.slice(
    indexOfFirstTransfer,
    indexOfLastTransfer
  );
  var pagesCount = Math.ceil(hotelsListing.length / TransferPerPage);
  var validCurrentPage = currentPage < 1 ? 1 : currentPage;
  var maxPagesToShow = 5;
  var startHotelIndex = indexOfFirstTransfer + 1;
  var endHotelIndex = Math.min(indexOfLastTransfer, hotelsListing.length);
  const paginate = (pageNumber) => {
    window.scrollTo(0, 0);
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    let startPage = currentPage - Math.floor(maxPagesToShow / 2);
    startPage = Math.max(startPage, 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, pagesCount);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => paginate(i)} href="#">
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return items;
  };
  useEffect(() => {
    // Adjust the current page if it exceeds the total number of pages
    if (currentPage < 1) {
      setCurrentPage(1);
    } else if (currentPage > pagesCount) {
      setCurrentPage(pagesCount);
    }
  }, [currentPage, pagesCount]);
  useEffect(() => {
    setHotelsData(hotelDataNew);
    setHotelsListing(hotelDataNew);
  }, [hotelDataNew, ReduxSearchData]);
  useEffect(() => {
    let Checkin = moment(ReduxSearchData.check_in);
    let checkout = moment(ReduxSearchData.check_out);
    let daysBetween = Math.abs(checkout.diff(Checkin, "days"));
    setNumberOfNights(daysBetween);
    setCurrentPage(1);
  }, [hotelsListing]);

  useEffect(() => {
    if (hotelid !== "") {
      if (typeof hotelid === "number") {
        filteredHotels = hotelDataNew.filter(
          (x) => x.hotel_id === Number(hotelid)
        );
        setHotelsListing(filteredHotels);
        setLoadingpage(false);
      } else if (hotelid.type === "Stars") {
        const filteredHotels = hotelDataNew.filter((hotel) => {
          const hotelRating = hotel.stars_rating;
          if (hotelRating !== "") {
            return Object.keys(hotelid).some(
              (ratingKey) => Number(hotelRating) === Number(hotelid[ratingKey])
            );
          }
        });
        setHotelsListing(filteredHotels);
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false);
      } else if (hotelid.type === "meal") {
        const filteredHotels = hotelDataNew.filter((hotel) => {
          // Check if any room in the hotel's rooms_options array has the specified boardtype
          return hotel.rooms_options.some((room) => {
            for (const mealKey in hotelid) {
              if (room.board_id === hotelid[mealKey]) {
                return true; // Found a match, include the hotel
              }
            }
            return false; // No match found for any meal key
          });
        });
        setHotelsListing(filteredHotels);
        setHotelDetails({});
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false);
      } else if (hotelid.type === "facility") {
        const filteredHotels = [];
        hotelDataNew.forEach((hotel) => {
          Facilityfilter(hotel.hotel_id, hotel.hotel_provider, (metaData) => {
            // Check if the hotel has the desired facilities (e.g., wifi and safe).
            for (const mealKey in hotelid) {
              if (metaData.facilities.includes(hotelid[mealKey])) {
                filteredHotels.push(hotel);
              }
            }
          });
        });
        setLoadingpage(false);
        setHotelDetails({});
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setHotelsListing(filteredHotels);
      } else if (hotelid.type === "price") {
        const filteredHotels = hotelDataNew.filter((hotel) => {
          let minPrice = Number(hotel.min_price);
          if (Number(hotel.admin_markup) !== 0) {
            if (hotel.admin_markup_type === "Percentage") {
              minPrice +=
                (Number(hotel.min_price) * Number(hotel.admin_markup)) / 100;
            } else {
              minPrice += Number(hotel.admin_markup);
            }
          }
          // Customer markup
          if (Number(hotel.customer_markup) !== 0) {
            if (hotel.customer_markup_type === "Percentage") {
              minPrice +=
                (Number(hotel.min_price) * Number(hotel.customer_markup)) / 100;
            } else {
              minPrice += Number(hotel.admin_markup);
            }
          }
          return minPrice >= hotelid.min && minPrice <= hotelid.max;
        });
        setHotelsListing(filteredHotels);
        setHotelDetails({});
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false);
      }else if (hotelid.type === 'order') {
        var filteredHotels = []
        if(hotelid.value==='1'){
          const sortedHotels = hotels.sort(
            (a, b) => a.min_price - b.min_price
          )
          filteredHotels=sortedHotels;
        }else if(hotelid.value==='2'){
          const sortedHotels = hotels.sort(
            (a, b) => b.min_price - a.min_price
          )
          filteredHotels=sortedHotels;
          
        }else if(hotelid.value==='3'){
          const sortedHotels = hotels.sort((a, b) =>
            a.hotel_name.localeCompare(b.hotel_name)
          );
          filteredHotels=sortedHotels;
        }else if(hotelid.value==='4'){
          const sortedHotels = hotels.sort((a, b) =>
            b.hotel_name.localeCompare(a.hotel_name)
          );
          filteredHotels=sortedHotels;
        }
        setHotelDetails({})
        filteredHotels.slice(0,15).forEach(hotel => {
          if (!hotelDetails[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
          }
        })
        setCurrentPage(1)
        setHotelsListing(filteredHotels)
        setLoadingpage(false)
      }
    }
  }, [hotelid]);

  const Facilityfilter = async (hotelId, provider, callback) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken,
    };
    try {
      const response = await Axios.post(endpoint + "/api/hotels/mata_Live", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      callback(response.data.details_data);
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    var currencyData = JSON.parse(localStorage.getItem("AllHotelCurr"));
    if (currencyData !== null) {
      setShowPrice(true);
    }
    filterHotels();
    setSearchData((prevdata) => ({
      ...prevdata,
      adult: ReduxSearchData.adult,
      child: ReduxSearchData.child,
      checkin: moment(ReduxSearchData.check_in).format("Do MMM "),
      checkout: moment(ReduxSearchData.check_out).format("Do MMM "),
    }));
  }, []);

  const filterHotels = () => {
    const newHotels = hotels.filter((hotel) => {
      // Check if there is at least one room option with a cancellation amount equal to the room price
      return hotel.rooms_options.some((roomOption) => {
        if (roomOption.cancliation_policy_arr.length > 0) {
          // Assuming there can be multiple cancellation policies, check if any of them has an amount equal to the room price
          return roomOption.cancliation_policy_arr.some(
            (policy) =>
              parseFloat(policy.amount) ===
              parseFloat(roomOption.rooms_total_price)
          );
        }
        return false;
      });
    });

    setShowHotels(newHotels);
  };

  const RoomDetailPage = async (id, index) => {
    const hotelRoomdetail = hotelDataNew.filter((item) => item.hotel_id == id);
    dispatch(fetchHotels(hotelRoomdetail));
    setLoadingpage(true);
    try {
      const data = {
        token: apitoken,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id),
      };
      const response = await Axios.post(
        endpoint + "/api/hotels/details_Live",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // Required for CORS support to work
            "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
            "Access-Control-Allow-Headers":
              "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          },
        }
      );
      if (response.data !== "") {
        setLoadingpage(false);
        dispatch(fetchHotelDetail(response.data.hotel_details));
        navigate(`/hotel_detail/${id}`, { state: { index } });
      } else {
        setLoadingpage(false);
        toast.info(
          "Something went wrong. Please select another hotel or try after some time thanks."
        );
      }
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      setLoadingpage(false);
      console.error("Error:", error);
    }
  };

  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken,
    };

    try {
      const response = await Axios.post(endpoint + "/api/hotels/mata_Live", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      setHotelDetails((prevDetails) => ({
        ...prevDetails,
        [hotelId]: response.data,
      }));
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    currentHotels.forEach((hotel) => {
      if (!hotelDetails[hotel.hotel_id]) {
        fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider);
      }
    });

    
  }, [currentPage, hotelsListing]);

  const renderPrice = (price, currency) => {
    var currencyData = JSON.parse(localStorage.getItem("AllHotelCurr"));
    var selectedcurr = localStorage.getItem("selectedCurrency");

    if (selectedcurr === currency || currencyData === null) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2);
      }
      return Number(price);
    } else {
      var filterroomcurrdata = currencyData.filter(
        (item) => item.base_code === currency
      );
      if (CurrencyRates === undefined) {
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName]; // Use square brackets to access the property
        var baseprice = Number(gbpprice) * Number(price);
      } else {
        var select123 = CurrencyRates.selectedcurrency;
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName];
        var baseprice1 = Number(gbpprice) * Number(price);
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123]; // Use square brackets to access the property
        var baseprice = Number(gbpprice2) * Number(baseprice1);
      }
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2);
      }
      return baseprice;
    }
  };

  const calculateMarkup = (
    price,
    adminmarkup,
    admintype,
    clientmarkup,
    clienttype
  ) => {
    let markupprice = 0;
    let adminmarkupprice = 0;
    let clientmarkupprice = 0;
    let finalpricemarkup = Number(price);
    if (Number(adminmarkup) !== 0) {
      if (admintype === "Percentage") {
        markupprice = (price * Number(adminmarkup)) / 100;
      } else {
        markupprice = Number(adminmarkup);
      }
      adminmarkupprice = markupprice;
      finalpricemarkup += markupprice;
      // savemarkup.admin=adminmarkupprice;
      // savemarkup.final=finalpricemarkup;
    }
    if (Number(clientmarkup) !== 0) {
      if (clienttype === "Percentage") {
        markupprice = (finalpricemarkup * Number(clientmarkup)) / 100;
      } else {
        markupprice = Number(clientmarkup);
      }
      clientmarkupprice = markupprice;
      finalpricemarkup += markupprice;
    }
    if (Number(finalpricemarkup) % 1 !== 0) {
      return Number(finalpricemarkup).toFixed(2);
    }
    return finalpricemarkup;
  };
  
  

  return (
    <>
      {Loadingpage && <Loading />}
      <ToastContainer />
      {currentHotels.map((item, index) => (
        <div class="row" key={index}>
          <div class="col-lg-12">
            <div class="cruise_search_result_wrapper">
              <div class="cruise_search_item">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="cruise_item_img cardd">
                      {hotelDetails[item.hotel_id] &&
                      hotelDetails[item.hotel_id].details_data &&
                      hotelDetails[item.hotel_id].details_data.image ? (
                        <img
                          src={hotelDetails[item.hotel_id].details_data.image}
                          class='img-fluid'
                          alt={item.hotel_name}
                          // width='800'
                          // height='533'
                        />
                      ) : (
                        <div data-loader="circle-side"></div>
                      )}
                  
                
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="cruise_item_inner_content">
                      <div class="cruise_content_top_wrapper">
                        <div class="cruise_content_top_left">
                          <h4>
                            {" "}
                            <a
                              onClick={() =>
                                RoomDetailPage(item.hotel_id, index)
                              }
                              className=""
                              style={{cursor:"pointer"}}
                            >
                              {" "}
                              {item.hotel_name}
                            </a>
                          </h4>
                          <small className="badge bg-secondary">{item.rooms_options.length} Rooms Available</small>
                          <p>
                            <i class="fas fa-map-marker-alt"></i>{" "}
                            {hotelDetails[item.hotel_id]?.details_data?.address}
                          </p>
                        </div>
                        <div class="cruise_content_top_right ">
                          <h4 className="badge bg-secondary">
                            {" "}
                            {searchData.checkin}
                        <FontAwesomeIcon icon={faArrowRight} />{' '}
                        {searchData.checkout}
                          </h4>
                          <h4>
                            {" "}
                            <span className="rate">
                              {item.stars_rating === "" ? (
                                <div
                                  class="cat_star"
                                  style={{ color: "#FF9800" }}
                                >
                                  No Rating
                                </div>
                              ) : (
                                <div
                                  class="cat_star"
                                  style={{ color: "#FF9800" }}
                                >
                                  {Array(item.stars_rating)
                                    .fill(0)
                                    .map((_, index) => (
                                      <i key={index} className="fa fa-star"></i>
                                    ))}
                                </div>
                              )}
                            </span>
                          </h4>
                        </div>
                      </div>
                      <div class="cruise_content_middel_wrapper">
                        
                        <div class="cruise_content_middel_right">
                          <p>{searchData.adult} {Number(searchData.adult) === 1 ? "Adult" : "Adults"} - {' '}
                             {searchData.child} {Number(searchData.child) === 1 ? "Children" : "Childrens"} - {' '}
                          {numberOfNights} {Number(numberOfNights) === 1 ? "Night" : "Nights"} </p>
                        </div>
                      </div>
                      <div class="cruise_content_middel_wrapper">
                        <div class="cruise_content_middel_left">
                          
                          <p>{showPrice ? (
                      <span class='price'>
                        From{' '}
                        <strong className="fs-5">
                          {CurrencyRates === undefined
                            ? baseCName
                            : CurrencyRates.selectedcurrency}{' '}
                          {renderPrice(
                            calculateMarkup(
                              item.min_price,
                              item.admin_markup,
                              item.admin_markup_type,
                              item.customer_markup,
                              item.customer_markup_type
                            ),
                            item?.hotel_curreny
                          )}
                        </strong>
                      </span>
                    ) : (
                      <span class='price'>
                        From{' '}
                        <strong>
                          {item?.hotel_curreny}{' '}
                          {calculateMarkup(
                            item.min_price,
                            item.admin_markup,
                            item.admin_markup_type,
                            item.customer_markup,
                            item.customer_markup_type
                          )}
                        </strong>
                      </span>
                    )}</p>
                    <p>inclusive of Vat and Taxes</p>
                        </div>
                        <div class="cruise_content_middel_right">
                          <p>
                          {showPrice ? (
                      <span class='price'>
                        <strong>
                          {CurrencyRates === undefined
                            ? baseCName
                            : CurrencyRates.selectedcurrency}{' '}
                          {(renderPrice(
                            calculateMarkup(
                              item.min_price,
                              item.admin_markup,
                              item.admin_markup_type,
                              item.customer_markup,
                              item.customer_markup_type
                            ),
                            item?.hotel_curreny
                          )/Number(numberOfNights)).toFixed(2)}  / night
                        </strong>
                      </span>
                    ) : (
                      <span class='price'>
                        <strong>
                          {item?.hotel_curreny}{' '}
                          {(calculateMarkup(
                            item.min_price,
                            item.admin_markup,
                            item.admin_markup_type,
                            item.customer_markup,
                            item.customer_markup_type
                          )/Number(numberOfNights)).toFixed(2)}/ night
                        </strong>
                      </span>
                    )}
                          </p>
                        
                        </div>
                      </div>
                      <div class="cruise_content_bottom_wrapper">
                        <div class="cruise_content_bottom_left">
                          <ul>
                           
                          {hotelDetails[item.hotel_id] &&
                    hotelDetails[item.hotel_id].details_data.facilities !==
                      '' ? (
                      <div>
                        {hotelDetails[
                          item.hotel_id
                        ].details_data.facilities.map((facility, index) => (
                          <li key={index} >
                            <FontAwesomeIcon icon={faCheck} /> {facility}
                          </li>
                        ))}
                      </div>
                    ) : (
                      <div className='placeholder-glow'>
                        <span className='placeholder col-12'></span>
                        <span className='placeholder col-12'></span>
                        <span className='placeholder col-6'></span>
                      </div>
                    )}
                          </ul>
                        </div>
                        <div class="cruise_content_bottom_right"  onClick={() => RoomDetailPage(item.hotel_id, index)}>
                          <a
                            class="btn btn_theme btn_md"

                          >
                            {
                            // Check if all rooms have request_type === 1
                            item.rooms_options.every(room => room.request_type === '1')
                              ? 'Book on request' // If all have request_type === 1
                              : 'Book Now' // If any room doesn't have request_type === 1
                          }
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* {currentHotels.map((item, index) => (
          <div key={index} class='isotope-item latest'>
            <div class='box_list' data-cue='slideInUp'>
              <div class='row g-0'>
                <div class='col-lg-5'>
                  <figure>
                    <small>{item.rooms_options.length} Rooms Available</small>
                    <a>
                      {hotelDetails[item.hotel_id] &&
                      hotelDetails[item.hotel_id].details_data &&
                      hotelDetails[item.hotel_id].details_data.image ? (
                        <img
                          src={hotelDetails[item.hotel_id].details_data.image}
                          class='img-fluid'
                          alt={item.hotel_name}
                          width='800'
                          height='533'
                        />
                      ) : (
                        <div data-loader="circle-side"></div>
                      )}
                    </a>
                  </figure>
                </div>
                <div class='col-lg-7'>
                  <div class='wrapper'>
                    <a class='wish_btn'>
                      <div class='location text-primary d-flex justify-content-between align-items-center'>
                        {searchData.checkin}
                        <FontAwesomeIcon icon={faArrowRight} />{' '}
                        {searchData.checkout}
                      </div>
                    </a>
                    {item.stars_rating === '' ? (
                      <div class='cat_star'>No Rating</div>
                    ) : (
                      <div class='cat_star'>
                        {Array(item.stars_rating)
                          .fill(0)
                          .map((_, index) => (
                            <i
                              style={{ color: '#fa5636' }}
                              key={index}
                              className='fa fa-star'
                            >
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                          ))}
                      </div>
                    )}

                    <h3>
                      <a
                        onClick={() => RoomDetailPage(item.hotel_id, index)}
                        className='hotel-card-name'
                      >
                        {' '}
                        {item.hotel_name}
                      </a>
                    </h3>

                    <div class='text-success'>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />{' '}
                      {hotelDetails[item.hotel_id]?.details_data?.address}
                    </div>
                    
                    {hotelDetails[item.hotel_id] &&
                    hotelDetails[item.hotel_id].details_data.facilities !==
                      '' ? (
                      <div class='row my-4'>
                        {hotelDetails[
                          item.hotel_id
                        ].details_data.facilities.map((facility, index) => (
                          <div key={index} className='col-md-6'>
                            <FontAwesomeIcon icon={faCheck} /> {facility}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className='placeholder-glow'>
                        <span className='placeholder col-12'></span>
                        <span className='placeholder col-12'></span>
                        <span className='placeholder col-6'></span>
                      </div>
                    )}
                    {showPrice ? (
                      <span class='price'>
                        From{' '}
                        <strong>
                          {CurrencyRates === undefined
                            ? baseCName
                            : CurrencyRates.selectedcurrency}{' '}
                          {renderPrice(
                            calculateMarkup(
                              item.min_price,
                              item.admin_markup,
                              item.admin_markup_type,
                              item.customer_markup,
                              item.customer_markup_type
                            ),
                            item?.hotel_curreny
                          )}
                        </strong>
                      </span>
                    ) : (
                      <span class='price'>
                        From{' '}
                        <strong>
                          {item?.hotel_curreny}{' '}
                          {calculateMarkup(
                            item.min_price,
                            item.admin_markup,
                            item.admin_markup_type,
                            item.customer_markup,
                            item.customer_markup_type
                          )}
                        </strong>
                      </span>
                    )}
                    <p className='vat-text mb-0'>inclusive of Vat and Taxes</p>
                  </div>
                  <ul>
                    <li>
                      {searchData.adult}-Adults,{' '}
                      {searchData.child}-Children <br/> {numberOfNights} Nights
                    </li>
                    <li>
                    {showPrice ? (
                      <span class='price'>
                        <strong>
                          {CurrencyRates === undefined
                            ? baseCName
                            : CurrencyRates.selectedcurrency}{' '}
                          {(renderPrice(
                            calculateMarkup(
                              item.min_price,
                              item.admin_markup,
                              item.admin_markup_type,
                              item.customer_markup,
                              item.customer_markup_type
                            ),
                            item?.hotel_curreny
                          )/Number(numberOfNights)).toFixed(2)}  / night
                        </strong>
                      </span>
                    ) : (
                      <span class='price'>
                        <strong>
                          {item?.hotel_curreny}{' '}
                          {(calculateMarkup(
                            item.min_price,
                            item.admin_markup,
                            item.admin_markup_type,
                            item.customer_markup,
                            item.customer_markup_type
                          )/Number(numberOfNights)).toFixed(2)}/ night
                        </strong>
                      </span>
                    )}
                      <div style={{ cursor: 'pointer' }} className="score">
                        <strong onClick={() => RoomDetailPage(item.hotel_id, index)}>
                          {
                            // Check if all rooms have request_type === 1
                            item.rooms_options.every(room => room.request_type === '1')
                              ? 'Book on request' // If all have request_type === 1
                              : 'Book Now' // If any room doesn't have request_type === 1
                          }
                        </strong>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))} */}

      <div className="bravo-pagination">
        <nav className="mt-2">
          <ul className="pagination">
            {/* Previous Button */}
            <li
              className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              aria-disabled={currentPage === 1 ? "true" : "false"}
              aria-label="&laquo; Previous"
            >
              <span
                aria-hidden="true"
                className="page-link"
                onClick={() => currentPage !== 1 && paginate(currentPage - 1)}
              >
                &lsaquo;
              </span>
            </li>

            {/* Calculate which page numbers to display */}
            {Array.from({ length: Math.min(pagesCount, 5) }, (_, index) => {
              const startPage = Math.max(1, currentPage - 2); // 2 pages before active
              const endPage = Math.min(pagesCount, currentPage + 2); // 2 pages after active

              const pageNumber = startPage + index;

              // Only render the page numbers between startPage and endPage
              if (pageNumber <= endPage) {
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      currentPage === pageNumber ? "active" : ""
                    }`}
                    aria-current={
                      currentPage === pageNumber ? "page" : undefined
                    }
                  >
                    {currentPage === pageNumber ? (
                      <span className="page-link">{pageNumber}</span>
                    ) : (
                      <a
                        className="page-link"
                        onClick={() => paginate(pageNumber)}
                      >
                        {pageNumber}
                      </a>
                    )}
                  </li>
                );
              }
              return null;
            })}

            {/* Next Button */}
            <li
              className={`page-item ${
                currentPage === pagesCount ? "disabled" : ""
              }`}
              aria-label="Next &raquo;"
            >
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
                rel="next"
              >
                &rsaquo;
              </a>
            </li>
          </ul>
        </nav>

        <span className="count-string">
          Showing {startHotelIndex} - {endHotelIndex} of {hotelsListing.length}{" "}
          Hotels
        </span>
      </div>
    </>
  );
}

export default HotelCard;
