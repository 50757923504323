import React, { useEffect, useState } from 'react'
import off3 from '../../Images/Home/9.jpg'
// import img from '../../Images/Home/homebg1.jpeg'
// import taif from '../../Images/Home/Taif.jpg'
// import jeddah from '../../Images/Home/jeddah.jpg'
import {
  ApiEndPoint,
  ActivityToken,
  CustomerDomainName
} from '../../Components/GlobalData/GlobalData'
import Axios from 'axios'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin } from '@fortawesome/free-solid-svg-icons'
function MoreOffer () {
  var navigation = useNavigate()
  var Domainpath = CustomerDomainName()
  const [topactivities, setTopActivities] = useState([])
  useEffect(() => {
    show()
  }, [])

  const show = async () => {
    var endpoint = ApiEndPoint()
    var token = ActivityToken()
    const currentDate = new Date()
    var data = {
      token: token,
      location: '',
      start_dates: moment(currentDate).format('DD-MM-YYYY')
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/search_activities_react',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )

      if (response.data.status === 'success') {
        const firstThreeRecords = response.data.tours.slice(0, 3)
        setTopActivities(firstThreeRecords)
      }
    } catch (error) {
      // Handle errors here

      console.error('Error:', error)
    }
  }

  const showDetail = id => {
    navigation(`/activity_details/${id}`)
  }

  return (
    <>
      {/* <div className=" more-offer2">
            <div className="text-center ">
                <h3 style={{color:'green'}} >Top Excursions</h3>
            </div>
        <div className="row mt-3">
            {topactivities.map((item,index)=>(
                     <div key={index} className=" col-md-4 more-offer1 text-center">
                      <div className="top_excursions_label">
                     <img className="dropdown "  onClick={()=>showDetail(item.id)} src={Domainpath+'/public/images/activites/'+item.featured_image} />
                     <div class='label-top shadow-sm fw-bold'><FontAwesomeIcon icon={faLocationPin}/> {item.location}</div>
                     </div>
                     <h5  onClick={()=>showDetail(item.id)} className="mt-2 dropdown"> {item.title}</h5>
                 </div>
            ))}
        </div>
        </div> */}

      <section id='offer_area' class='section_padding_top'>
        <div class='container'>
          <div class='row'>
            {topactivities.map((item, index) => (
              <div class='col-lg-4 col-md-6 col-sm-12 col-12' key={index}>
                <div
                  class='offer_area_box img_animation'
                  onClick={() => showDetail(item.id)}
                >
                  <img
                    onClick={() => showDetail(item.id)}
                    src={
                      Domainpath +
                      'public/images/activites/' +
                      item.featured_image
                    }
                    alt='img'
                    style={{ height: '400px' }}
                  />
                  <div class='offer_area_content'>
                    <h2 onClick={() => showDetail(item.id)}>{item.title}</h2>
                    <p>{item.location}</p>
                  </div>
                </div>
              </div>
            ))}
            {/* <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="offer_area_box d-none-phone img_animation">
                  <img src="" alt="img" style={{height:"400px"}} />
                  <div class="offer_area_content">
                    <h2>Special Offers</h2>
                    <p>
                      Invidunt ut labore et dolore magna aliquyam erat, sed diam
                      voluptua. At vero eos et accusam et justo duo dolores et
                      ea rebum. Stet clita kasd dolor sit amet. Lorem ipsum
                      dolor sit amet.
                    </p>
                    <a  class="btn btn_theme btn_md">
                      Holiday deals
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                <div class="offer_area_box img_animation">
                  <img src="" alt="img" style={{height:"400px"}} />
                  <div class="offer_area_content">
                    <h2>News letter</h2>
                    <p>
                      Invidunt ut labore et dolore magna aliquyam erat, sed diam
                      voluptua. At vero eos et.
                    </p>
                    <a class="btn btn_theme btn_md">
                      Subscribe now
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                <div class="offer_area_box img_animation">
                  <img src="" alt="img" style={{height:"400px"}} />
                  <div class="offer_area_content">
                    <h2>Travel tips</h2>
                    <p>
                      Invidunt ut labore et dolore magna aliquyam erat, sed diam
                      voluptua. At vero eos et.
                    </p>
                    <a  class="btn btn_theme btn_md">
                      Get tips
                    </a>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </section>
    </>
  )
}
export default MoreOffer
