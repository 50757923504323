import React from 'react'
import Layout from '../../Components/Layout/Layout'
import img from './faq.jpg'
import cimg from '../../img/banner/common-banner.png'
import { NavLink } from 'react-router-dom'
function PrivacyPolicy () {
  const sectionStyle = {
    background: `url(${cimg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height : "100%"

  };
  return (
    <>
      <Layout>
        <main>
        <section id="common_banner" style={sectionStyle}>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>Privacy Policy</h2>
                        <ul>
                        <li><NavLink to="/">Home</NavLink></li>
                            <li><span><i class="fas fa-circle"></i></span>Policy</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </main>
        <div className='container'>
          <div className='row mt-3 text-center'>
            <h3>Privacy and Security Policy</h3>
          </div>
          <div className='row mt-4 '>
            <p className='5'>
              First things first - your privacy is important to us Hashim
              Travel Limited and we cherish your trust. Besides, we take the
              protection of your personal information very seriously. This
              document would help you to know what information we maintain and
              discard and how we use, store and protect your personal data.
            </p>
          </div>
          <div className='row mt-4 '>
            <h4>Why We Need to Collect or Process Your Personal Data?</h4>
            <p className='FontSize15'>
              We cannot help you arrange and book the perfect travel tours and
              services without your information. Therefore, the main purpose we
              ask you for personal details is to help you with the requested
              tours or services and ensure you get the best service possible, or
              for other purposes for which you have given your consent, except
              where otherwise provided by law. We also use your personal date to
              improve our services.
            </p>
          </div>
          <div className='row mt-4 '>
            <h4>When We Collect or Process Your Personal Data?</h4>
            <p className='FontSize15'>
              We mainly collect personal information only when you provide it to
              us, through registration, completion of forms or e-mails, as part
              of an order for travel service and tour requests and similar
              situations in which you have chosen to provide the information to
              us. In addition, we collect information from the computer, phone,
              tablet or other device you use to access our website, like IP
              address, the browser you’re using and your language settings, to
              make sure we give you a consistent user experience. We also use
              website analytics tools or other technologies like cookies to help
              us to give you the best possible service.
            </p>
          </div>
          <div className='row mt-4 '>
            <h4>What Kinds of Information We Collect from You? </h4>
            <p className='FontSize15'>
              We collect the following personal details from you:
            </p>
            <ul>
              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  Required information to make reservations, such as name and
                  email address (at a minimum). Depending on the policies of
                  places to visit, we may also ask your phone number, zip code,
                  passport and visa data
                  </p>
              </li>

              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  Your nationality and interests and special requests for your
                  food, accommodation, etc. in your trip.
                </p>
              </li>
              <li style={{listStyle:"square"}}  className='my-3'>
                <p>Unique Identifiers such as user name, account number.</p>
              </li>
              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  Billing Information such as credit card number, and billing
                  address.
                </p>
              </li>
            </ul>
          </div>

          <div className='row mt-4 '>
            <h4>What Do We Use Your Information For?</h4>
            <ul className='FontSize15' >
              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  To design a tailor-made trip to your best interests. Your
                  information helps us to better respond to your individual
                  needs while customizing your tour.
                </p>
              </li>

              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  To improve our website. We continually strive to improve our
                  website offerings based on the information and feedback we
                  receive from you.
                </p>
              </li>
              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  To improve customer service. Your information helps us to more
                  effectively respond to your customer service requests and
                  support needs.
                </p>
              </li>
              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  To process transactions. Your information, whether public or
                  private, will not be sold, exchanged, transferred, or given to
                  any other company for any reason whatsoever, without your
                  consent.
                </p>
              </li>
              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  To send newsletters as your request. With your permission, we
                  may send you our latest newsletters about travel news, updated
                  tours, or service information, etc. to the email address you
                  provide for order processing. You can unsubscribe at each
                  email at any time.
                </p>
              </li>
              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  In some cases, if we’re required to by law, we might share
                  your data with governmental or other authorities.
                </p>
              </li>
            </ul>
          </div>
          <div className='row mt-4 '>
            <h4>How Does We Protect Your Information? </h4>
            <p className='FontSize15'>
              Your personal information will not be passed on by us for use by
              third parties in any form whatsoever, unless we have obtained your
              consent or are legally required to do so. We have procedures in
              place to prevent unauthorized access to, and the misuse of,
              personal data. We use appropriate business systems and procedures
              to protect and safeguard the personal data you give us. We also
              use security procedures and technical and physical restrictions
              for accessing and using the personal data on our servers. Only
              authorized personnel and your required service providers are
              permitted to access personal data in the course of their work.
            </p>
          </div>
          <div className='row mt-4 '>
            <h4>Third Party Responsibility and Your Responsibility </h4>
            <p className='FontSize15'>
              Our website includes links to other sites, Facebook, YouTube, etc.
              If you visit these sites from our website, you should know that
              your information is governed by their privacy statements. Hashim
              Travel Limited don’t take any responsibility for the protection of
              your personal data during your visit of these sites. With your
              permission, we share your travel stories, images and reviews on
              our website, Facebook page or other platforms to other travelers.
              Once you send us such information by email, WeChat, Message or
              other way, you understand and agree that your information is
              allowed to be published publicly and to be read by others who
              access them. In some cases, your information may also be collected
              and used by others. Please note that if you share information of
              the person or people in your group to us, it’s your responsibility
              to ensure that the person or people you have provided personal
              data about are aware that you’ve done so and that they have read,
              understood and accepted our private policy.
            </p>
          </div>
          <div className='row mt-4 '>
            <h4>How to Contact Us?</h4>
            <p className='FontSize15'>
              Your personal will is crucial to us and your questions will always
              fully be appreciated by us Hashim Travel Limited. If you have
              any problem about our privacy policy, please feel free to contact
              us at hashimtravel@hotmail.co.uk.
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PrivacyPolicy
