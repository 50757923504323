import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cimg from '../../img/banner/common-banner.png'
import { NavLink } from 'react-router-dom'
import 'react-image-gallery/styles/css/image-gallery.css'
import {
  faBowlFood,
  faChild,
  faShower,
  faRefresh,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import noImage from '../../Images/Hotels/no-img.jpg'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import { fetchCheckoutDetail, fetchHotelDetail } from '../../Redux/Actions/actions'
import Layout from '../../Components/Layout/Layout'
import Axios from 'axios'
import ImageGallery from 'react-image-gallery'
import { DateRangePicker, Tooltip,Popover,Modal,Button, Whisper,Placeholder } from 'rsuite'
const { beforeToday } = DateRangePicker;
function HotelDetail () {
  const location = useLocation()
  const dispatch = useDispatch()
  const todayDate = new Date()
  const apitoken = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const { index } = location.state || {}
  const [selectedDate, setSelectedDate] = useState(null)
  const [activeButton, setActiveButton] = useState(null)
  const [selectedRooms, setSelectedRooms] = useState([])
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(false)
  const [showPrice, setShowPrice] = useState(true)
  const [baseCName, setBaseCName] = useState('GBP')
  const [currencyRates, setCurrencyRates] = useState([])
  const [counter, setCounter] = useState(0)
  const [roomName, setRoomName] = useState('')
  const [CancellationArr, setCancellationArr] = useState([])
  const [loadingCancellation, setLoadingCancellation] = useState(true)
  const [open, setOpen] = React.useState(false)
  const [isWhisperOpen, setIsWhisperOpen] = useState(false);
  const [isCustomSearch, setIsCustomSearch] = useState(false);
  const [childAgesError, setChildAgesError] = useState({});
  const [numberOfNights, setNumberOfNights] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [dateRange, setDateRange] = useState({
    checkindate: null,
    Checkoutdate: null,
  });
  const [personData, setPersonData] = useState({
    adult: 2,
    children: 0,
    room: 1,
  });
  const [rooms, setRooms] = useState([
    {
      adults: 2,
      children: 0,
      childrenAges: [],
    },
  ]);
  const handleCloseWhisper = () => {
    let ageStatus = false;
    let messages = {}; // Store error messages for each room and child

    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i];

      if (children !== childrenAges.length) {
        for (let j = 0; j < children; j++) {
          if (!childrenAges[j]) {
            // Check if age is missing for a specific child
            messages[
              `room-${i}-child-${j}`
            ] = `Please select child age.`;
            ageStatus = true;
          }
        }
      }
    }

    if (ageStatus) {
      setChildAgesError(messages); // Store messages for each specific child missing an age
      return;
    }

    setChildAgesError({});
    setIsWhisperOpen(false);
    
  };
  const SelectedCurrRates = useSelector(state => state.hotels.Currency)

  const BaseCurrRates = useSelector(state => state.hotels.AllCurrency)
  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([])
  const [priceConversion, setPriceConversion] = useState(false)

  const navigate = useNavigate()
  const hoteldetail = useSelector(state => state.hotels.hoteldetail)
  const seleectedhotelfromlist = useSelector(state => state.hotels.hotels[0])
  let { id } = useParams()
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const ReduxSearchData = useSelector(state => state.hotels.hsearch)

  let Checkin = moment(ReduxSearchData.check_in)
  let checkout = moment(ReduxSearchData.check_out)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const latitude = hoteldetail.latitude
  const longitude = hoteldetail.longitude
  var mapUrl =
    'https://maps.google.com/maps?width=50%25&height=600&hl=en&q=' +
    latitude +
    ',' +
    longitude +
    '&t=&z=19&ie=UTF8&iwloc=B&output=embed'

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    let NewArray = [];
    let occurrences = {};
    for (let i = 0; i < ReduxSearchData?.Adults.length; i++) {
      let adult = ReduxSearchData?.Adults[i];
      let child = ReduxSearchData?.children[i];
      let pairKey = `${adult}-${child}`;

      occurrences[pairKey] = (occurrences[pairKey] || 0) + 1;

      if (occurrences[pairKey] === 1) {
        NewArray.push({
          adult,
          child,
          qty: occurrences[pairKey],
          status: false,
        });
      } else {
        NewArray.find(
          (entry) => entry.adult === adult && entry.child === child
        ).qty = occurrences[pairKey];
      }
    }
    setResult(NewArray);
    const checkInDatec = new Date(ReduxSearchData.check_in);
    const checkOutDatec = new Date(ReduxSearchData.check_out);
    let Checkin = moment(ReduxSearchData.check_in);
    let checkout = moment(ReduxSearchData.check_out);
    let daysBetween = Math.abs(checkout.diff(Checkin, "days"));
    setNumberOfNights(daysBetween);
    setDateRange([checkInDatec, checkOutDatec]);
    const roomsData = ReduxSearchData.rooms_counter.map((_, index) => {
      const childAgesKey = `child_ages${index + 1}`;
      return {
        adults: ReduxSearchData.Adults[index],
        children: ReduxSearchData.children[index],
        childrenAges: ReduxSearchData[childAgesKey] || [], // Assuming this is empty for now, you can modify as needed
      };
    });
    setRooms(roomsData);
  }, [ReduxSearchData]);
  useEffect(() => {
    var currdata = localStorage.getItem('AllHotelCurr')
    if (currdata !== 'null' && currdata !== null) {
      setPriceConversion(true)
      setCurrencyRates(currdata)
    }
  }, [])

  useEffect(() => {
    let NewArray = []
    let occurrences = {}
    // Iterate over the adults and childs arrays
    for (let i = 0; i < ReduxSearchData.Adults.length; i++) {
      let adult = ReduxSearchData.Adults[i]
      let child = ReduxSearchData.children[i]
      // Create a unique identifier for the pair
      let pairKey = `${adult}-${child}`

      // Update the occurrences object
      occurrences[pairKey] = (occurrences[pairKey] || 0) + 1

      // Only add to the result if the current qty is the maximum
      if (occurrences[pairKey] === 1) {
        NewArray.push({
          adult,
          child,
          qty: occurrences[pairKey],
          status: false
        })
      } else {
        // Update the qty for the existing entry
        NewArray.find(
          entry => entry.adult === adult && entry.child === child
        ).qty = occurrences[pairKey]
      }
    }

    setResult(NewArray)
  }, [ReduxSearchData])
  // const selectedRoomData = (index, event) => {
  //   event.preventDefault()
  //   event.stopPropagation()

  //   if (selectedRooms.some(room => room.index === index)) {
  //     const selectedroom = hoteldetail.rooms_options[index]
  //     setCounter(counter - selectedroom.rooms_qty)
  //     // Check if there's a room with the same index in selectedRooms
  //     const updatedSelectedRooms = selectedRooms.filter(
  //       room => room.index !== index
  //     )
  //     const newroom = hotelSelectedRooms.filter(item => item.index !== index)
  //     // Update the selectedRooms state with the new array
  //     setSelectedRooms(updatedSelectedRooms)
  //     setHotelSelectedRooms(newroom)
  //   } else {
  //     if (counter < ReduxSearchData.room) {

  //       const selectedroom = hoteldetail.rooms_options[index]
  //       const key = { rateKey: selectedroom.booking_req_id, index: index }
  //       const newSelectedRoom = {
  //         room_rate_key: JSON.stringify(key),
  //         rooms_qty: selectedroom.rooms_qty,
  //         index: index,
  //         roomdetail:selectedroom.adults+selectedroom.childs
  //       }
  //       setCounter(counter + selectedroom.rooms_qty)
  //       setSelectedRooms([...selectedRooms, newSelectedRoom])
  //       setHotelSelectedRooms([...hotelSelectedRooms, key])
  //     } else {
  //       toast.error('Select the room according to the search criteria.', {
  //         position: toast.POSITION.TOP_RIGHT
  //     });
  //     }
  //   }
  // };
  // const selectedRoomData = (index, event) => {
  //   event.preventDefault()
  //   event.stopPropagation()
  //   const selectedroom = hoteldetail.rooms_options[index]
  //   for (let i = 0; i < result.length; i++) {
  //     if (
  //       result[i].adult === selectedroom.adults &&
  //       result[i].child === selectedroom.childs &&
  //       result[i].qty === selectedroom.rooms_qty
  //     ) {
  //       if (result[i].status === false) {
  //         const key = { rateKey: selectedroom.booking_req_id, index: index }
  //         const newSelectedRoom = {
  //           room_rate_key: JSON.stringify(key),
  //           rooms_qty: selectedroom.rooms_qty,
  //           index: index
  //         }
  //         result[i].status = true
  //         setSelectedRooms([...selectedRooms, newSelectedRoom])
  //         setHotelSelectedRooms([...hotelSelectedRooms, key])
  //       } else {
  //         if (selectedRooms.some(room => room.index === index)) {
  //           const updatedSelectedRooms = selectedRooms.filter(
  //             room => room.index !== index
  //           )
  //           const newroom = hotelSelectedRooms.filter(
  //             item => item.index !== index
  //           )
  //           setSelectedRooms(updatedSelectedRooms)
  //           setHotelSelectedRooms(newroom)
  //           result[i].status = false
  //         } else {
  //           toast.error('Select the room according to the search criteria.', {
  //             position: toast.POSITION.TOP_RIGHT
  //           })
  //         }
  //       }
  //     }
  //   }
  // }
  const selectedRoomData = (index, event, provider) => {
    event.preventDefault();
    event.stopPropagation();
    if (provider === "Custome_hotel") {
      if (selectedRooms.some((room) => room.index === index)) {
        const updatedSelectedRooms = selectedRooms.filter(
          (room) => room.index !== index
        );
        const newroom = hotelSelectedRooms.filter(
          (item) => item.index !== index
        );
        setSelectedRooms(updatedSelectedRooms);
        setHotelSelectedRooms(newroom);
      } else {
        const selectedroom = hoteldetail.rooms_options[index];
        const key = { rateKey: selectedroom.booking_req_id, index: index };
        const newSelectedRoom = {
          room_rate_key: JSON.stringify(key),
          rooms_qty: selectedroom.rooms_qty,
          index: index,
        };
        setSelectedRooms([...selectedRooms, newSelectedRoom]);
        setHotelSelectedRooms([...hotelSelectedRooms, key]);
      }
    } else {
      const selectedroom = hoteldetail.rooms_options[index];
      for (let i = 0; i < result.length; i++) {
        if (
          result[i].adult === selectedroom.adults &&
          result[i].child === selectedroom.childs &&
          result[i].qty === selectedroom.rooms_qty
        ) {
          if (result[i].status === false) {
            const key = { rateKey: selectedroom.booking_req_id, index: index };
            const newSelectedRoom = {
              room_rate_key: JSON.stringify(key),
              rooms_qty: selectedroom.rooms_qty,
              index: index,
            };
            result[i].status = true;
            setSelectedRooms([...selectedRooms, newSelectedRoom]);
            setHotelSelectedRooms([...hotelSelectedRooms, key]);
          } else {
            if (selectedRooms.some((room) => room.index === index)) {
              const updatedSelectedRooms = selectedRooms.filter(
                (room) => room.index !== index
              );
              const newroom = hotelSelectedRooms.filter(
                (item) => item.index !== index
              );
              setSelectedRooms(updatedSelectedRooms);
              setHotelSelectedRooms(newroom);
              result[i].status = false;
            } else {
              toast.error("Select the room according to the search criteria.");
            }
          }
        }
      }
    }
  };
  const SelectedRoomQuitity = (event, index) => {
    const qty = event.target.value;
    const updatedRooms = selectedRooms.map((room) => {
      if (room.index === index) {
        return {
          ...room,
          rooms_qty: qty, // update rooms_qty for the specific index
        };
      }
      return room; // keep the other rooms unchanged
    });

    setSelectedRooms(updatedRooms);
  };
  const BookRoom = async () => {
    if (hotelSelectedRooms.length !== 0) {
      if (hotelSelectedRooms.length !== result.length) {
        toast.error(
          'Please Select the room according to the search criteria.',
          {
            position: toast.POSITION.TOP_RIGHT
          }
        )
        return
      }
      if (hoteldetail.hotel_provider !== "Custome_hotel") {
        if (hotelSelectedRooms.length !== result.length) {
          toast.error("Please Select the room according to the search criteria.");
          return;
        }
      }
      if (hoteldetail.hotel_provider === "Custome_hotel") {
        var customresult = checkcustomhotelvalidation();
        if (customresult == false) {
          toast.error("Please Select the room according to the search criteria.");
          return;
        }
      }
      const roomdata = {
        rooms_select_data: JSON.stringify(selectedRooms),
        hotel_index: index,
        hotelbeds_select_room: hotelSelectedRooms
      }
      const finaldata = {
        token: apitoken,
        request_data: JSON.stringify(roomdata),
        selected_hotel: JSON.stringify(seleectedhotelfromlist),
        selected_hotel_details: JSON.stringify({
          check_in: ReduxSearchData.check_in,
          check_out: ReduxSearchData.check_out,
          hotel_address: hoteldetail.hotel_address,
          hotel_country: hoteldetail.hotel_country,
          hotel_city: hoteldetail.hotel_city,
          latitude: hoteldetail.longitude,
          longitude: hoteldetail.latitude
        }),
        req_index: index
      }
      setLoading(true)
      try {
        const response = await Axios.post(
          endpoint + '/api/hotels/checkavailability_Live',
          finaldata,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              // Required for CORS support to work
              'Access-Control-Allow-Credentials': true // Required for cookies, authorization headers with HTTPS
              // 'Access-Control-Allow-Headers':
              //   'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
              // "Access-Control-Allow-Methods": "POST, OPTIONS"
            }
          }
        )

        if (response.data.status === 'error') {
          setLoading(false)
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          })
          return
        }
        if (response.data !== '') {
          setLoading(false)
          toast.error('Sorry Room Not Available.')
          if(response.data?.hotels_data?.rooms_list.length === 0){
            return;
          }
          dispatch(fetchCheckoutDetail(response.data.hotels_data))
          navigate('/hotel_checkout')
        }
        setLoading(false)
      } catch (error) {
        // Handle errors here
        setLoading(false)
        console.error('Error:', error)
      }
    } else {
      toast.error('Please Select Room First.', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }
  const checkcustomhotelvalidation = () => {
    let nadult = 0;
    let nchild = 0;

    selectedRooms.forEach((item) => {
      const hroom = hoteldetail.rooms_options[item.index];
      nadult += Number(hroom.adults) * Number(item.rooms_qty);
      nchild += Number(hroom.childs) * Number(item.rooms_qty);
    });
    // console.log(nadult);
    // console.log(nchild);
    if (nadult >= ReduxSearchData.adult && nchild >= ReduxSearchData.child) {
      return true;
    } else {
      return false;
    }
  };
  const renderPrice = (price, currency) => {
    var currencyData = JSON.parse(localStorage.getItem('AllHotelCurr'))
    var selectedcurr = localStorage.getItem('selectedCurrency')
    if (selectedcurr === currency || currencyData === null) {
      setShowPrice(false)
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2)
      }
      return Number(price)
    } else {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === currency
      )
      if (CurrencyRates === undefined) {
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName] // Use square brackets to access the property
        var baseprice = Number(gbpprice) * Number(price)
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName]
        var baseprice1 = Number(gbpprice) * Number(price)
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = Number(gbpprice2) * Number(baseprice1)
      }
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2)
      }
      return baseprice
    }
  }
  const calculateMarkup = price => {
    if (Object.keys(hoteldetail).length !== 0) {
      let markupprice = 0
      let adminmarkupprice = 0
      let clientmarkupprice = 0
      let finalpricemarkup = Number(price)
      if (Number(hoteldetail.admin_markup) !== 0) {
        if (hoteldetail.admin_markup_type === 'Percentage') {
          markupprice = (price * Number(hoteldetail.admin_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.admin_markup)
        }
        adminmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(hoteldetail.customer_markup) !== 0) {
        if (hoteldetail.customer_markup_type === 'Percentage') {
          markupprice =
            (finalpricemarkup * Number(hoteldetail.customer_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.customer_markup)
        }
        clientmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      return finalpricemarkup.toFixed(2)
    }
  }

  const [isExpanded, setIsExpanded] = useState(false)

  // Function to handle toggling the description
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded)
  }

  // Get the hotel description and format it
  const description = hoteldetail.description || ''

  // If description is from TBO or GoGlobal, use dangerouslySetInnerHTML
  const isHTMLDescription =
    hoteldetail.hotel_provider === 'tbo' ||
    hoteldetail.hotel_provider === 'GoGlobal' ||
    hoteldetail.hotel_provider === 'travelenda'

  // Helper function to truncate to first 5 lines (approximation by limiting characters)
  const truncatedDescription =
    description.split(' ').slice(0, 80).join(' ') + '...' // Customize the split for more accuracy
  const sectionStyle = {
    background: `url(${cimg}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%'
  }

  const ShowCancellationPolicy = async (id, cancell_arr, name) => {
    setRoomName(name)
    if (
      hoteldetail.hotel_provider !== 'travelenda' &&
      hoteldetail.hotel_provider !== 'Stuba'
    ) {
      setLoadingCancellation(false)
      setCancellationArr(cancell_arr)
      console.log(cancell_arr);
      setOpen(true)
      return
    }
    setLoadingCancellation(true)
    var data = {
      token: apitoken,
      booking_req_id: id,
      hotel_provider: hoteldetail.hotel_provider
    }
    setOpen(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/all_Hotel_Cancellation_Policy_Live',
        data
      )
      if (response.status === 200) {
        if (hoteldetail.hotel_provider === 'travelenda') {
          const firstCancellationDeadline = response.data.find(
            item => item.CancellationDeadline
          )?.CancellationDeadline
          const deadline = new Date(firstCancellationDeadline)
          const nowdate = new Date()
          if (deadline < nowdate || firstCancellationDeadline === '') {
            setCancellationArr([])
            setLoadingCancellation(false)
          } else {
            setCancellationArr(response.data)
            setLoadingCancellation(false)
          }
        } else {
          setCancellationArr(response.data)
          setLoadingCancellation(false)
        }
      }
    } catch (error) {
      setLoadingCancellation(false)
      console.error(error)
    }
  }

  // Modify Search

  useEffect(() => {
    const adults = rooms.reduce((acc, item) => acc + item.adults, 0);
    const children = rooms.reduce((acc, item) => acc + item.children, 0);
    const roomss = rooms.length;
    setPersonData((prevPersonData) => ({
      ...prevPersonData,
      adult: adults,
      children: children,
      room: roomss,
    }));
  }, [rooms]);
  const handleDateRangeChange = (value) => {
    setDateRange(value);
  };
  const handleAdultIncrement = (roomIndex) => {
    // Increment the number of adults for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].adults < 6) {
      updatedRooms[roomIndex].adults += 1;
      setRooms(updatedRooms);
    }
  };
  const handleAdultDecrement = (roomIndex) => {
   
    // Decrement the number of adults for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].adults > 1) {
      updatedRooms[roomIndex].adults -= 1;
      updatedRooms[roomIndex].childrenAges.pop();
      setRooms(updatedRooms);
    }
  };
  const handleChildrenIncrement = (roomIndex) => {
    // Increment the number of children for a specific room, if less than 4
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].children < 4) {
      updatedRooms[roomIndex].children += 1;
      setRooms(updatedRooms);
    }
  };
  const handleChildrenDecrement = (roomIndex) => {
    debugger
    // Decrement the number of children for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].children > 0) {
      updatedRooms[roomIndex].children -= 1;
      updatedRooms[roomIndex].childrenAges.pop();
      setRooms(updatedRooms);
    }
  };
  const handleChildAgeChange = (roomIndex, childIndex, value) => {
    // Update the age of a specific child for a specific room
    const updatedRooms = [...rooms];
    updatedRooms[roomIndex].childrenAges[childIndex] = value;
    setRooms(updatedRooms);
  };
  const addRoom = () => {
    // Add a new room with default values
    if (rooms.length < 9) {
      setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }]);
    }
  };
  const removeLastRoom = () => {
    if (rooms.length > 1) {
      // Remove the last room by slicing the array
      setRooms(rooms.slice(0, -1));
    }
  };
  const handleDeleteRoomByIndex = (roomIndex) => {
    const updatedRooms = rooms.filter((room, index) => index !== roomIndex);
    setRooms(updatedRooms);
  };
  const childAgearray = [
    "1 Year",
    "2 Year",
    "3 Year",
    "4 Year",
    "5 Year",
    "6 Year",
    "7 Year",
    "8 Year",
    "9 Year",
    "10 Year",
  ];
  const speaker = (
    <Popover style={{ width: "21em" }} title="Select Detail">
      {rooms.map((room, roomIndex) => (
        <div key={roomIndex} className="availability_search">
          <div
            className="hotel-detail-border d-flex text-center fw-bold mb-2"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="fw-bold text-black m-1">Room {roomIndex + 1}</p>
            {roomIndex > 0 && ( // Check if roomIndex is greater than 0
              <button
                className="delete-room-button m-1"
                onClick={() => handleDeleteRoomByIndex(roomIndex)}
              >
                Delete
              </button>
            )}
          </div>
          <div>
            <div className="select-child">
              <div class="f4878764f1">
                <label class="a984a491d9 fw-bold" for="group_adults">
                  Adults
                </label>
              </div>
              <div class="d-flex fff">
                <button
                  className="adult-modal-btn"
                  name={`adults-${roomIndex}`}
                  onClick={() => handleAdultDecrement(roomIndex)}
                >
                  <i class="fas ">
                    <FontAwesomeIcon size="sm" icon={faMinus} />
                  </i>
                </button>
                <span className="d723d73d5f fw-bold" id="input">
                  {room.adults}
                </span>
                <button
                  className="adult-modal-btn"
                  name={`adults-${roomIndex}`}
                  onClick={() => handleAdultIncrement(roomIndex)}
                  id="increment"
                >
                  <i class="fas ">
                    <FontAwesomeIcon size="sm" icon={faPlus} />
                  </i>
                </button>
              </div>
            </div>
            <div className="select-child">
              <div class="f4878764f1">
                <label class="a984a491d9 fw-bold" for="group_adults">
                  Children
                </label>
              </div>
              <div class="d-flex fff">
                <button
                  className="adult-modal-btn"
                  onClick={() => handleChildrenDecrement(roomIndex)}
                  id="decrement"
                >
                  <i class="fas ">
                    <FontAwesomeIcon size="sm" icon={faMinus} />
                  </i>
                </button>
                <span className="d723d73d5f fw-bold" id="input">
                  {room.children}
                </span>
                <button
                  className="adult-modal-btn"
                  onClick={() => handleChildrenIncrement(roomIndex)}
                  id="increment"
                >
                  <i class="fas ">
                    <FontAwesomeIcon size="sm" icon={faPlus} />
                  </i>
                </button>
              </div>
            </div>
            <div className="select-child" style={{ justifyContent: "left" }}>
              {room.children > 0 && (
                <div
                  style={{ flexWrap: "wrap" }}
                  className="d-flex justify-content-between"
                >
                  {Array.from({ length: room.children }, (_, childIndex) => (
                    <div key={childIndex} className="m-1 ">
                      <label>Child {childIndex + 1} (Age)</label>
                      <select
                        class="form-select child-age-select"
                        name="child1"
                        value={room.childrenAges[childIndex]}
                        onChange={(e) =>
                          handleChildAgeChange(
                            roomIndex,
                            childIndex,
                            e.target.value
                          )
                        }
                        aria-label="Default select example"
                      >
                        <option selected>Age needed</option>
                        {childAgearray.map((item, index) => (
                          <option key={index} value={index + 1}>
                            {item}
                          </option>
                        ))}
                      </select>
                      {childAgesError[
                                `room-${roomIndex}-child-${childIndex}`
                              ] && (
                                <p className="text-danger small">
                                  {
                                    childAgesError[
                                      `room-${roomIndex}-child-${childIndex}`
                                    ]
                                  }
                                </p>
                              )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <div>
        <div className="select-child availability_search mt-4">
          <div class="f4878764f1 pe-4">
            <label class="a984a491d9 fw-bold" for="group_adults">
              Room
            </label>
          </div>
          <div class="d-flex fff">
            <button
              className="adult-modal-btn"
              onClick={removeLastRoom}
              id="decrement"
            >
              <i class="fas ">
                <FontAwesomeIcon size="sm" icon={faMinus} />
              </i>
            </button>
            <span className="d723d73d5f fw-bold" id="input">
              {rooms.length}
            </span>
            <button
              className="adult-modal-btn"
              onClick={addRoom}
              id="increment"
            >
              <i class="fas ">
                <FontAwesomeIcon size="sm" icon={faPlus} />
              </i>
            </button>
          </div>
          <button onClick={handleCloseWhisper} className="theme-btn-1 ps-3 pe-3 py-1">Apply</button>
        </div>
      </div>
    </Popover>
  );
  const GetCustonHotelsRooms = async () => {
    if (dateRange === null) {
      toast.error("Please Select Checkin and Checkout Date.");
      return;
    }
    const roomsSummary = rooms.reduce(
      (acc, item, index) => {
        acc.adult += item.adults;
        acc.child += item.children;
        acc.room += 1;
        acc.Adults.push(item.adults);
        acc.children.push(item.children);
        acc[`child_ages${index + 1}`] = item.childrenAges.map((age) =>
          age ? parseInt(age, 10) : 2
        );
        acc.rooms_counter.push(acc.room);
        return acc;
      },
      {
        adult: 0,
        child: 0,
        room: 0,
        Adults: [],
        children: [],
        rooms_counter: [],
      }
    );
    ReduxSearchData.check_in = moment(dateRange[0]).format("YYYY-MM-DD");
    ReduxSearchData.check_out = moment(dateRange[1]).format("YYYY-MM-DD");
    ReduxSearchData.adult = roomsSummary.adult;
    ReduxSearchData.child = roomsSummary.child;
    ReduxSearchData.children = roomsSummary.children;
    ReduxSearchData.Adults = roomsSummary.Adults;
    ReduxSearchData.room = roomsSummary.room;
    ReduxSearchData.rooms_counter = roomsSummary.rooms_counter;
    ReduxSearchData.hotel_provider = hoteldetail.hotel_provider;
    ReduxSearchData.token = apitoken;
    ReduxSearchData.hotel_Id = hoteldetail.hotel_code;
    roomsSummary.rooms_counter.forEach((_, index) => {
      ReduxSearchData[`child_ages${index + 1}`] =
        roomsSummary[`child_ages${index + 1}`];
    });
    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i];

      if (children !== childrenAges.length) {
        toast.info(
          `Room ${
            i + 1
          }: You selected ${children} children but provided age(s) for only ${
            childrenAges.length
          }. Please select the ages for all children.`
        );
        return;
      }
    }
    setLoading(true);
    try {
      const response = await Axios.post(
        endpoint + "/api/custom_Search_Hotels",
        ReduxSearchData
      );
      setLoading(false);
      if (response.data.status === "success") {
        setIsCustomSearch(true);
        dispatch(fetchHotelDetail(response.data.hotel_details));
        // var x = []; // Initialize an array
        //   x[0] = response.data.hotel_details;
        // dispatch(setSearchResults(x));
        setSelectedRooms([]);
        setHotelSelectedRooms([]);
        let NewArray = [];
        let occurrences = {};
        for (let i = 0; i < ReduxSearchData?.Adults.length; i++) {
          let adult = ReduxSearchData?.Adults[i];
          let child = ReduxSearchData?.children[i];
          let pairKey = `${adult}-${child}`;

          occurrences[pairKey] = (occurrences[pairKey] || 0) + 1;

          if (occurrences[pairKey] === 1) {
            NewArray.push({
              adult,
              child,
              qty: occurrences[pairKey],
              status: false,
            });
          } else {
            NewArray.find(
              (entry) => entry.adult === adult && entry.child === child
            ).qty = occurrences[pairKey];
          }
        }
        setResult(NewArray);
        const checkInDatec = new Date(ReduxSearchData.check_in);
        const checkOutDatec = new Date(ReduxSearchData.check_out);
        let Checkin = moment(ReduxSearchData.check_in);
        let checkout = moment(ReduxSearchData.check_out);
        let daysBetween = Math.abs(checkout.diff(Checkin, "days"));
        setNumberOfNights(daysBetween);
        setDateRange([checkInDatec, checkOutDatec]);
        const roomsData = ReduxSearchData.rooms_counter.map((_, index) => {
          const childAgesKey = `child_ages${index + 1}`;
          return {
            adults: ReduxSearchData.Adults[index],
            children: ReduxSearchData.children[index],
            childrenAges: ReduxSearchData[childAgesKey], // Assuming this is empty for now, you can modify as needed
          };
        });
        setRooms(roomsData);
      } else {
        hoteldetail.rooms_options=[]
        dispatch(fetchHotelDetail(hoteldetail));
        // toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <ToastContainer />
      <div
        class='modal fade'
        id='staticBackdrop'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabindex='-1'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h5 class='modal-title' id='staticBackdropLabel'>
                {roomName} - Cancellation Policy
              </h5>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div class='modal-body'>
              {loadingCancellation ? (
                <Placeholder.Paragraph />
              ) : (
                <div>
                  {CancellationArr.length !== 0 ? (
                    CancellationArr.map((policy, index) => {
                      debugger
                      return(
                      <div key={index}>
                        {policy.type === 'Fix Amount' ||
                        policy.type === 'Amount' ? (
                          <>
                            <h6>
                              
                              {priceConversion == false
                                ? hoteldetail.hotel_curreny
                                :SelectedCurrRates===undefined || Object.keys(SelectedCurrRates).length === 0
                                ? BaseCurrRates.base_code
                                : SelectedCurrRates.base_code}{' '}
                              {priceConversion == false
                                ? calculateMarkup(Number(policy.amount))
                                : renderPrice(
                                    calculateMarkup(Number(policy.amount)),
                                    hoteldetail.hotel_curreny
                                  )}{' '}
                              {policy.type} will be charged after{' '}
                              {moment(policy.from_date).format('lll')}
                            </h6>
                          </>
                        ) : (
                          <h6 className='mb-2 mt-2'>
                           
                            {policy.amount} {policy.type} will be charged after{' '}
                            {moment(policy.from_date).format('lll')}
                          </h6>
                        )}
                      </div>
                     )})
                  ) : (
                    <h6 className='text-danger text-center mb-2 mt-2'>
                      Non-Refundable
                    </h6>
                  )}
                </div>
              )}
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-secondary'
                data-bs-dismiss='modal'
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <section id='common_banner' style={sectionStyle}>
            <div class='container'>
              <div class='row'>
                <div class='col-lg-12'>
                  <div class='common_bannner_text'>
                    <h2>Hotel Details</h2>
                    <ul>
                      <li>
                        <NavLink to='/'>Home</NavLink>
                      </li>
                      <li>
                        <span>
                          <i class='fas fa-circle'></i>
                        </span>
                        Hotel Details
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id='tour_details_main' class='section_padding'>
            <div class='container'>
              <div class='row'>
                <div class='col-lg-8'>
                  <div class='tour_details_leftside_wrapper'>
                    <div class='tour_details_heading_wrapper'>
                      <div class='tour_details_top_heading'>
                        <h2>{hoteldetail.hotel_name}</h2>
                      </div>
                    </div>

                    <div class='tour_details_boxed'>
                      <h3 class='heading_theme'>Description</h3>
                      <div class='tour_details_boxed_inner'>
                        <p>
                          {isHTMLDescription ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: isExpanded
                                  ? description
                                  : truncatedDescription
                              }}
                            />
                          ) : (
                            <p>
                              {isExpanded ? description : truncatedDescription}
                            </p>
                          )}

                          {/* Show Read More/Read Less button */}
                          <button
                            onClick={toggleReadMore}
                            className='btn btn-link btn_theme mb-0 ps-1 text-decoration-none'
                          >
                            {isExpanded ? 'Read Less' : 'Read More'}
                          </button>
                        </p>

                        <ul>
                          <li>
                            <h6>
                              {' '}
                              <i class='fas fa-circle'></i> Check In
                            </h6>
                            <p>
                              {moment(ReduxSearchData.check_in).format('ll')}
                            </p>
                          </li>

                          <li>
                            <h6>
                              <i class='fas fa-circle'></i> Check out
                            </h6>
                            <p>
                              {moment(ReduxSearchData.check_out).format('ll')}
                            </p>
                          </li>
                          <li>
                            <h6>
                              {' '}
                              <i class='fas fa-circle'></i> No of Nights
                            </h6>
                            <p>{daysBetween}</p>
                          </li>
                        </ul>

                        <ImageGallery
                          showFullscreenButton={true}
                          items={hoteldetail.hotel_gallery.map(image => ({
                            original: image, // large image
                            thumbnail: image // thumbnail
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-4  col-sm-12 mt-5'>
                  <div class='tour_detail_right_sidebar '>
                    <div class='tour_details_right_boxed'>
                      <div class='tour_package_details_bar_list'>
                        <h5>Hotel facilities</h5>
                        <ul>
                          {hoteldetail.hotel_facilities
                            .slice(0, 20)
                            .map((item, index) => (
                              <div key={index}>
                                <ul>
                                  <li>
                                    <i className='fas fa-circle'></i> {item}
                                  </li>
                                </ul>
                              </div>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div>
              <h3 className="heading_theme">Modify Search</h3>
              </div>
                <div className="col-md-4 col-sm-6 mt-1 col-12">
                  <DateRangePicker
                    onChange={handleDateRangeChange}
                    value={dateRange}
                    showOneCalendar={isMobile}
                    disabledDate={beforeToday()}
                    format="dd-MM-yyyy"
                    className="w-100 text-left "
                  />
                </div>
                <div className="col-md-4 col-sm-6 mt-1 col-12">
                  <Whisper
                   open={isWhisperOpen}
                    placement="bottom"
                    speaker={speaker}
                    preventClose
                  >
                    <button
                     onClick={()=>setIsWhisperOpen(true)}
                      className="btn text-left  adult-btn  w-100 btn-block "
                      style={{ color: "black", background: "none",border:'.5px solid #80808038' }}
                    >
                      {personData.adult} Adults . {personData.children} Children
                      . {personData.room} Room
                    </button>
                  </Whisper>
                </div>
                <div className="col-md-4 col-sm-6 mt-1 col-12">
                  <button
                    onClick={GetCustonHotelsRooms}
                    className="btn btn_theme btn-md side-btn"
                  >
                    <FontAwesomeIcon className="ms-2 " icon={faRefresh} />{" "}
                    Modify
                  </button>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-9 col-sm-10'>
                  <h3 class='heading_theme mt-2'>Select your room</h3>
                  {hoteldetail.rooms_options.map((item, index) => (
                    <div class='tour_details_boxed' key={index}>
                      <div class='room_select_area'>
                        <div class='tab-content' id='myTabContent'>
                          <div
                            class='tab-pane fade show active'
                            id='home'
                            role='tabpanel'
                            aria-labelledby='home-tab'
                          >
                            <div class='room_booking_area'>
                              <div class='room_book_item'>
                                <div class='room_book_img'>
                                  {item.rooms_images &&
                                  item.rooms_images.length > 0 ? (
                                    <img
                                      className='img-fluid'
                                      src={item.rooms_images[0]}
                                      alt={item.room_name}
                                      style={{
                                        height: '17em',
                                        width: '30em',
                                        objectFit: 'cover'
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={
                                        hoteldetail?.hotel_gallery.length !== 0
                                          ? hoteldetail?.hotel_gallery[0]
                                          : noImage
                                      }
                                      className='img-fluid'
                                      alt='Default Image'
                                      style={{
                                        height: '100%',
                                        objectFit: 'cover'
                                      }}
                                    />
                                  )}
                                </div>

                                <div class='room_booking_right_side'>
                                  <div class='room_booking_heading'>
                                    <h3>
                                      {item.room_name}
                                      {item.request_type === '1' && (
                                        <div className='room-request mb-2'>
                                          <h6>Room on Request</h6>
                                        </div>
                                      )}
                                    </h3>

                                    <div class='room_fasa_area'>
                                      <ul class='room-meta my-2'>
                                        <li v-if='room.beds_html'>
                                          <Whisper
                                            placement='top'
                                            controlId='control-id-hover'
                                            trigger='hover'
                                            speaker={
                                              <Tooltip>No. Rooms</Tooltip>
                                            }
                                          >
                                            <div
                                              class='item'
                                              data-toggle='tooltip'
                                              data-placement='top'
                                              title=''
                                              data-original-title='No. Beds'
                                            >
                                              <i class='fa fa-bed'></i>{' '}
                                              <span v-html='room.beds_html'>
                                                x{item.rooms_qty}
                                              </span>
                                            </div>
                                          </Whisper>
                                        </li>
                                        <li v-if='room.adults_html'>
                                          <Whisper
                                            placement='top'
                                            controlId='control-id-hover'
                                            trigger='hover'
                                            speaker={
                                              <Tooltip>No. Adults</Tooltip>
                                            }
                                          >
                                            <div
                                              class='item'
                                              data-toggle='tooltip'
                                              data-placement='top'
                                              title=''
                                              data-original-title='No. Adults'
                                            >
                                              <i class='fa-solid fa-child'></i>{' '}
                                              <span v-html='room.adults_html'>
                                                x{item.adults}
                                              </span>
                                            </div>
                                          </Whisper>
                                        </li>
                                        <li v-if='room.children_html'>
                                          <Whisper
                                            placement='top'
                                            controlId='control-id-hover'
                                            trigger='hover'
                                            speaker={
                                              <Tooltip>No. Children</Tooltip>
                                            }
                                          >
                                            <div
                                              class='item'
                                              data-toggle='tooltip'
                                              data-placement='top'
                                              title=''
                                              data-original-title='No. Children'
                                            >
                                              <i class='input-icon field-icon fa-child fa'>
                                                <FontAwesomeIcon
                                                  icon={faChild}
                                                />
                                              </i>{' '}
                                              <span v-html='room.children_html'>
                                                x{item.childs}
                                              </span>
                                            </div>
                                          </Whisper>
                                        </li>
                                        <li v-if='room.children_html'>
                                          <Whisper
                                            placement='top'
                                            controlId='control-id-hover'
                                            trigger='hover'
                                            speaker={
                                              <Tooltip>{item.board_id}</Tooltip>
                                            }
                                          >
                                            <div
                                              class='item'
                                              data-toggle='tooltip'
                                              data-placement='top'
                                              title=''
                                              data-original-title='No. Children'
                                            >
                                              <FontAwesomeIcon
                                                icon={faBowlFood}
                                              />{' '}
                                              <span v-html='room.children_html'>
                                                Meal
                                              </span>
                                            </div>
                                          </Whisper>
                                        </li>
                                      </ul>
                                      {item.rooms_facilities &&
                                        item.rooms_facilities.length > 0 && (
                                          <ul class='hotel_facilities'>
                                            {(item.rooms_facilities.includes(
                                              'Wake-up service'
                                            ) ||
                                              item.rooms_facilities.includes(
                                                'Alarm clock'
                                              )) && (
                                              <li>
                                                <i class='icofont-wall-clock h6 me-2'></i>
                                                Wake-up service
                                              </li>
                                            )}
                                            {item.rooms_facilities.includes(
                                              'TV'
                                            ) && (
                                              <li>
                                                <i class='icofont-monitor h6 me-2'></i>
                                                TV
                                              </li>
                                            )}
                                            {item.rooms_facilities.includes(
                                              'Shower'
                                            ) && (
                                              <li>
                                                <FontAwesomeIcon
                                                  className='me-2'
                                                  icon={faShower}
                                                />
                                                Shower
                                              </li>
                                            )}
                                            {item.rooms_facilities.includes(
                                              'Safe'
                                            ) && (
                                              <li>
                                                <i class='icofont-safety h6 me-2'></i>
                                                Safe
                                              </li>
                                            )}
                                            {item.rooms_facilities.includes(
                                              'Living room'
                                            ) && (
                                              <li>
                                                <i class='icofont-bed h6 me-2'></i>
                                                Living room
                                              </li>
                                            )}
                                          </ul>
                                        )}
                                    </div>
                                    <div>
                                      {' '}
                                      {showPrice ? (
                                        <h6 className=''>
                                          {CurrencyRates === undefined
                                            ? baseCName
                                            : CurrencyRates.selectedcurrency}{' '}
                                          {renderPrice(
                                            calculateMarkup(
                                              item.rooms_total_price
                                            ),
                                            hoteldetail.hotel_curreny
                                          )}
                                          <br />
                                          <p className='lh-sm fs-6 mb-0'>
                                            inclusive of Vat and Taxes
                                          </p>
                                        </h6>
                                      ) : (
                                        <h6 className=''>
                                          {hoteldetail.hotel_curreny}{' '}
                                          {calculateMarkup(
                                            item.rooms_total_price
                                          )}
                                          <br />
                                          <p className='lh-sm fs-6 mb-0'>
                                            inclusive of Vat and Taxes
                                          </p>
                                        </h6>
                                      )}
                                      <p
                                        onClick={() =>
                                          ShowCancellationPolicy(
                                            item.booking_req_id,
                                            item.cancliation_policy_arr,
                                            item.room_name
                                          )
                                        }
                                        class='text-danger btn'
                                        data-bs-toggle='modal'
                                        data-bs-target='#staticBackdrop'
                                      >
                                        Show Cancellation Policy
                                      </p>
                                    </div>
                                  </div>

                                  <div class='room_person_select'>
                                    <div className=' p-night'>
                                      {showPrice ? (
                                        <h6 className='me-2  p-night'>
                                          {CurrencyRates === undefined
                                            ? baseCName
                                            : CurrencyRates.selectedcurrency}{' '}
                                          {(
                                            renderPrice(
                                              calculateMarkup(
                                                item.rooms_total_price
                                              ),
                                              hoteldetail.hotel_curreny
                                            ) / Number(daysBetween)
                                          ).toFixed(2)}
                                          /night
                                        </h6>
                                      ) : (
                                        <h6 className='me-2  p-night'>
                                          {hoteldetail.hotel_curreny}{' '}
                                          {(
                                            calculateMarkup(
                                              item.rooms_total_price
                                            ) / Number(daysBetween)
                                          ).toFixed(2)}
                                          /night
                                        </h6>
                                      )}
                                    </div>
                                    <h3>
                                      {hotelSelectedRooms.some(
                                        item => item.index === index
                                      ) ? (
                                        <p
                                          onClick={event =>
                                            selectedRoomData(index, event,hoteldetail.hotel_provider)
                                          }
                                          class='btn btn-sm btn-danger mt-4 mb-0'
                                        >
                                          Remove
                                        </p>
                                      ) : (
                                        <p
                                          onClick={event =>
                                            selectedRoomData(index, event,hoteldetail.hotel_provider)
                                          }
                                          class='btn btn-md btn_theme mt-4  mb-0'
                                        >
                                          Select Room
                                        </p>
                                      )}
                                      {HotelDetail.hotel_provider ===
                                  "Custome_hotel" &&
                                  hotelSelectedRooms.some(
                                    (item) => item.index === index
                                  ) && (
                                    <div>
                                      <p className='medium'>Select room Qty</p>
                                      <select
                                        onChange={(event) =>
                                          SelectedRoomQuitity(event, index)
                                        }
                                        className="my-1 form-select"
                                        aria-label="Default select example"
                                      >
                                        {Array.from(
                                          { length: item.room_Quantity  },
                                          (_, i) => (
                                            <option key={i} value={i + 1}>
                                              {i + 1}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  )}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='col-md-3 col-sm-2'>
                  <button
                    className='btn btn_theme mt-2 btn-md side-btn'
                    onClick={BookRoom}
                  >
                    Book Now
                  </button>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-9'>
                  <div class='tour_details_boxed'>
                    <h3 class='heading_theme'>Hotel location</h3>
                    <div class='map_area'>
                      <div style={{ width: '100%' }}>
                        <iframe
                          width='100%'
                          height='300'
                          frameborder='0'
                          scrolling='no'
                          marginheight='0'
                          marginwidth='0'
                          src={mapUrl}
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </>
  )
}

export default HotelDetail
